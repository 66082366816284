import * as React from 'react'
import { Link, graphql } from 'gatsby'

import { MDXRenderer } from 'gatsby-plugin-mdx'
import { chakra } from '@chakra-ui/react'
import Layout from '../components/layout'
import Seo from '../components/seo'
import '../styles/blog-post.scss'

const BlogPostTemplate = ({
  data: { previous, next, site, mdx: post },
  children,
  location,
}: {
  data: Queries.BlogPostBySlugQuery
  children: any
  location: any
}) => {
  const siteTitle = site?.siteMetadata?.title || `Title`

  return (
    <Layout location={location} title={siteTitle}>
      <chakra.article
        className="blog-post"
        itemScope
        itemType="http://schema.org/Article"
        padding="40px"
        borderLeft="1px solid rgba(0,0,0,0.1)"
        borderRight="1px solid rgba(0,0,0,0.1)"
      >
        <chakra.header mb="20px">
          <chakra.h1
            fontWeight="bold"
            lineHeight="1.8"
            fontSize="18px"
            itemProp="headline"
          >
            {post?.frontmatter?.title}
          </chakra.h1>
          <chakra.p fontSize="14px" textAlign="right">
            {post?.frontmatter?.date}
          </chakra.p>
        </chakra.header>

        <chakra.div className="post-content">
          <MDXRenderer frontmatter={post?.frontmatter}>
            {post?.body || ''}
          </MDXRenderer>
        </chakra.div>
        {/*<chakra.section dangerouslySetInnerHTML={{ __html: post.body }} ></chakra.section>*/}
        {/*<div dangerouslySetInnerHTML={{ __html: post.body }}></div>*/}
        <hr />
        <footer>{/*<Bio />*/}</footer>
      </chakra.article>
      <nav className="blog-post-nav">
        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li>
            {previous && (
              <Link to={previous.frontmatter?.slug || ''} rel="prev">
                ← {previous.frontmatter?.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={next.frontmatter?.slug || ''} rel="next">
                {next.frontmatter?.title} →
              </Link>
            )}
          </li>
        </ul>
      </nav>
    </Layout>
  )
}

export const Head = ({ data: { mdx: post } }) => {
  return (
    <Seo
      title={post.frontmatter.title}
      description={post.frontmatter.description || post.excerpt}
    />
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    mdx(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      body
      frontmatter {
        title
        date(formatString: "YYYY-MM-DD")
        description
      }
    }
    previous: mdx(id: { eq: $previousPostId }) {
      frontmatter {
        title
        slug
      }
    }
    next: mdx(id: { eq: $nextPostId }) {
      frontmatter {
        title
        slug
      }
    }
  }
`
